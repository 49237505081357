@import 'scss-reset/_reset.scss';

@font-face {
    font-family: 'spacegrotesk';
    src: url('../../assets/fonts/spacegrotesk-regular.woff2') format('woff2'),
        url('../../assets/fonts/spacegrotesk-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'spacegrotesk';
    src: url('../../assets/fonts/spacegrotesk-light.woff2') format('woff2'),
        url('../../assets/fonts/spacegrotesk-light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'spacegrotesk';
    src: url('../../assets/fonts/spacegrotesk-medium.woff2') format('woff2'),
        url('../../assets/fonts/spacegrotesk-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'spacegrotesk';
    src: url('../../assets/fonts/spacegrotesk-semibold.woff2') format('woff2'),
        url('../../assets/fonts/spacegrotesk-semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'spacegrotesk';
    src: url('../../assets/fonts/spacegrotesk-bold.woff2') format('woff2'),
        url('../../assets/fonts/spacegrotesk-bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'cormorantgaramond';
    src: url('../../assets/fonts/cormorantgaramond-semibolditalic.woff2')
            format('woff2'),
        url('../../assets/fonts/cormorantgaramond-semibolditalic.woff')
            format('woff');
    font-weight: 600;
    font-style: italic;
}

$font: 'spacegrotesk', 'Verdana', 'Geneva', 'Tahoma', sans-serif;

$primary: #2d287e;
$secondary: #3adcbe;

$size: 16px;
$line-height: 24px;

body {
    font-family: $font;
    font-size: $size;
    line-height: $line-height;
    font-weight: 400;
    color: $primary;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body {
    overflow-x: hidden;
    max-width: 100%;
}

a,
a:focus,
a:visited,
a:active {
    text-decoration: none;
    outline: none;
    color: $primary;
    font-weight: 500;
}

input:focus,
input:visited,
input:link,
input:active,
textarea:focus,
textarea:visited,
textarea:link,
textarea:active,
select:focus,
select:visited,
select:link,
select:active {
    text-decoration: none;
    outline: none;
    border: none;
}

#root {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.btn {
    position: relative;
    button,
    input {
        background-color: $secondary;
        padding: 15px 30px;
        border: 2px solid $secondary;
        border-radius: 5px;
        color: white;
        font-weight: 500;
        position: relative;
        z-index: 2;
        box-shadow: 0 0 0 0 rgba($color: $secondary, $alpha: 0.4);
        transition: all 0.2s ease-out;
    }
    &::before {
        content: '';
        width: 70%;
        height: 20px;
        background: rgb(21, 255, 144);
        background: linear-gradient(
            145deg,
            rgba(21, 255, 144, 1) 0%,
            rgba(94, 232, 239, 1) 100%
        );
        filter: blur(1rem);
        -webkit-filter: blur(1rem);
        -moz-filter: blur(1rem);
        border-radius: 5px;
        display: block;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        z-index: 1;
    }
    &:hover {
        button,
        input {
            background-color: white;
            box-shadow: 0 0 10px 4px rgba($color: $secondary, $alpha: 0.4);
            color: $secondary;
        }
    }
}
.marge button {
    padding: 15px 45px;
}

.page_cont {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
}
.title {
    font-size: 3rem;
    line-height: 3.4rem;
    font-weight: 700;
    margin-bottom: 20px;
}
.subtitle {
    font-size: 1.3rem;
    line-height: 1.7rem;
    margin-bottom: 10px;
}

header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px 0;
        font-size: 1rem;
        font-weight: 500;
        transition: all 0.2s ease-out;
        .logo_container {
            display: flex;
            align-items: center;
            .logo {
                height: 45px;
            }
            .sep {
                height: 27px;
                width: 1px;
                background-color: #dddddd;
                margin: 0 20px;
            }
            .veeva {
                height: 45px;
            }
        }
        .headlink {
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 0.9rem;
            strong {
                position: relative;
                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    transform: scaleX(0);
                    height: 1px;
                    bottom: 0;
                    left: 0;
                    background-color: $primary;
                    transform-origin: bottom right;
                    transition: transform 0.25s ease-out;
                }
            }
            &:hover {
                strong::after {
                    transform: scaleX(1);
                    transform-origin: bottom left;
                }
            }
        }
    }
}
.headerscroll {
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 10px 20px 0 rgba(31, 38, 135, 0.03);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    -moz-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    .header {
        padding: 15px 0;
    }
}
.slider_cont {
    background-color: white;
    padding-top: 114px;
    position: relative;
    .slider {
        display: flex;
        flex-direction: column;
        .slide {
            display: flex;
            align-items: center;
            height: 65vh;
            .content {
                width: 55%;
                p {
                    width: 83%;
                    margin-bottom: 60px;
                    font-size: 1.4rem;
                    line-height: 1.8rem;
                }
                .cta {
                    display: flex;
                    align-items: center;
                    gap: 60px;
                }
            }
            .kv {
                width: 45%;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                .deg {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 110%;
                    height: 0;
                    padding-top: 100%;
                    z-index: 1;
                    svg {
                        height: 100%;
                        width: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
                img {
                    position: relative;
                    z-index: 2;
                }
            }
            .link {
                position: relative;
                a button {
                    color: $primary;
                }
                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    transform: scaleX(0);
                    height: 2px;
                    bottom: 0;
                    left: 0;
                    background-color: $primary;
                    transform-origin: bottom right;
                    transition: transform 0.25s ease-out;
                }
                &:hover:after {
                    transform: scaleX(1);
                    transform-origin: bottom left;
                }
            }
        }
        .reference {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
        }
    }
    .scroll {
        position: absolute;
        bottom: 175px;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
    }
}
.services {
    .services_cont {
        position: relative;
        .description {
            display: flex;
            align-items: center;
            padding: 80px 0;
            .content {
                width: 52%;
                h2 {
                    margin-bottom: 30px;
                    width: 72%;
                }
                p {
                    margin-bottom: 60px;
                }
            }
            .arrow {
                width: 8%;
                img {
                    margin-bottom: 160px;
                }
            }
            .image {
                width: 40%;
                text-align: center;
                .im {
                    position: relative;
                    .relief {
                        filter: blur(3rem);
                        -webkit-filter: blur(3rem);
                        -moz-filter: blur(3rem);
                        display: block;
                        position: absolute;
                        left: 50%;
                        top: 60%;
                        transform: translate(-50%, -50%);
                        z-index: -1;
                    }
                }
            }
        }
        .bull1,
        .bull2,
        .bull3 {
            position: absolute;
            width: 846px;
            height: 846px;
            background: radial-gradient(
                circle,
                rgba(#5ee8ef, 1) 0%,
                rgba(#5ee8ef, 0) 70%
            );
            opacity: 0.45;
            z-index: -3;
        }
        .bull1 {
            right: -70%;
            top: -10%;
        }
        .bull2 {
            left: -70%;
            top: 0%;
        }
        .bull3 {
            left: -60%;
            top: -70%;
            z-index: -2;
            background: radial-gradient(
                circle,
                rgba(#15ff99, 1) 0%,
                rgba(#15ff99, 0) 70%
            );
            opacity: 0.26;
        }
    }
    .services_icons {
        background-color: white;
        position: relative;
        margin-top: 100px;
        &::before {
            content: '';
            background: url('../images/bg-rounded.svg') no-repeat top center;
            background-size: 150%;
            height: 0;
            width: 100%;
            display: block;
            padding-top: 10%;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: -135px;
            z-index: -1;
        }
        .services_icons_cont {
            .flex {
                display: flex;
                justify-content: center;
                gap: 3.33%;
                margin-bottom: 50px;
                div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 20px;
                    width: 20%;
                    img {
                        height: 117px;
                        width: 117px;
                    }
                    h4 {
                        font-weight: 700;
                        text-align: center;
                    }
                }
            }
        }
    }
}

.maitrise {
    background-color: #f7f7f7;
    .maitrise_cont {
        .description {
            text-align: center;
            display: flex;
            flex-direction: column;
            width: 70%;
            margin: 0 auto;
            img {
                height: 145px;
                margin-bottom: 40px;
            }
            h2 {
                margin: 0 auto;
                margin-bottom: 30px;
                width: 90%;
            }
        }
        .details {
            display: flex;
            align-items: flex-start;
            gap: 11%;
            padding: 60px 0;
            div {
                width: 26%;
                .icon {
                    height: 185px;
                    margin-bottom: 40px;
                }
                h3 {
                    display: flex;
                    align-items: flex-start;
                    gap: 10px;
                    margin-bottom: 10px;
                    img {
                        height: 43px;
                    }
                    span {
                        font-size: 1.8rem;
                        font-weight: 700;
                        line-height: 2.2rem;
                    }
                }
                p {
                    font-size: 1.1rem;
                    line-height: 1.5rem;
                    margin-bottom: 40px;
                }
                ul {
                    list-style-image: url('../images/puce.svg');
                    list-style-position: outside;
                    margin: 0 0 0 1em;
                    li {
                        padding-left: 10px;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}
.references {
    .references_cont {
        padding: 80px 0;
        .description {
            text-align: center;
            display: flex;
            flex-direction: column;
            width: 80%;
            margin: 0 auto;
            h2 {
                margin: 0 auto;
                margin-bottom: 30px;
                width: 90%;
            }
        }
        .refs {
            width: 90%;
            margin: 0 auto;
            h4 {
                font-size: 0.9rem;
                line-height: 1.3rem;
                font-weight: 700;
                color: white;
                border-radius: 0 5px 5px 0;
                padding: 8px 15px;
                display: inline-block;
            }
            .block {
                margin: 30px 0;
                .logos {
                    display: flex;
                    gap: 6.66%;
                    flex-wrap: wrap;
                    width: 100%;
                    margin: 0 auto;
                    align-items: center;
                    div {
                        width: 20%;
                        padding: 30px 20px;
                        text-align: center;
                    }
                }
            }
            .flex {
                display: flex;
                align-items: stretch;
                .double {
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    h4 {
                        width: max-content;
                    }
                    .logos {
                        display: flex;
                        gap: 5%;
                        height: 100%;
                        div {
                            width: 45%;
                        }
                    }
                }
                .single {
                    width: 25%;
                    display: flex;
                    flex-direction: column;
                    h4 {
                        width: max-content;
                    }
                    .logos {
                        gap: 0;
                        display: flex;
                        height: 100%;
                        div {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.realisations {
    .realisations_cont {
        padding: 40px 0;
        .description {
            text-align: center;
            display: flex;
            flex-direction: column;
            width: 80%;
            margin: 0 auto;
            h2 {
                margin: 0 auto;
                margin-bottom: 30px;
                width: 90%;
            }
        }
        .real {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 50px 0;
            .realisation {
                border-radius: 40px;
                padding: 60px 70px 40px;
                background-image: url('../images/splash.svg');
                background-repeat: no-repeat;
                .imagereal {
                    position: relative;
                    margin-bottom: 70px;
                    width: 100%;
                    .img {
                        position: relative;
                        z-index: 2;
                        border-radius: 20px;
                        overflow: hidden;
                        height: 0;
                        padding-bottom: 66.6%;
                        img {
                            width: 100%;
                            transition: margin 1s ease-out;
                        }
                    }
                    .relief {
                        filter: blur(1rem);
                        -webkit-filter: blur(1rem);
                        -moz-filter: blur(1rem);
                        display: block;
                        position: absolute;
                        left: 50%;
                        bottom: -25px;
                        transform: translateX(-50%);
                        height: 45px;
                        z-index: 1;
                        width: 80%;
                        transition: position 0.5s ease-out;
                    }
                    .logocont {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        opacity: 0;
                        transition: opacity 0.5s ease-out;
                        img {
                            width: 40%;
                        }
                    }
                    .linkreal {
                        font-size: 1.2rem;
                        line-height: 1.6rem;
                        text-decoration: underline;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        width: 100%;
                        text-align: center;
                        transition: margin 0.5s ease-out;
                        display: none;
                    }
                }
                .imgscroll:hover {
                    .img {
                        img {
                            // margin-top: -290px;
                            margin-top: -75%;
                        }
                    }
                }
                .imglogo {
                    transition: all 0.5s ease-out;
                    &:hover {
                        margin-top: -25%;
                        padding-bottom: 25%;
                        .logocont {
                            opacity: 1;
                        }
                        .linkreal {
                            margin-top: 45%;
                        }
                        .relief {
                            bottom: 20%;
                        }
                    }
                }
                h3 {
                    font-size: 2.6rem;
                    line-height: 3rem;
                    font-weight: 700;
                    margin-bottom: 10px;
                }
                p {
                    font-size: 1.6rem;
                    line-height: 2rem;
                    font-weight: 500;
                }
            }
            .demi {
                width: 49%;
                margin-bottom: 40px;
            }
            .tiere,
            .tiere_double {
                width: 32%;
                padding: 40px 45px 30px;
                margin-bottom: 40px;
                h3 {
                    font-size: 2rem;
                    line-height: 2.4rem;
                    font-weight: 700;
                    margin-bottom: 5px;
                }
                p {
                    font-size: 1.2rem;
                    line-height: 1.6rem;
                    font-weight: 500;
                }
                .imagereal {
                    margin-bottom: 45px;
                    .img {
                        border-radius: 10px;
                    }
                    .relief {
                        bottom: -20px;
                        height: 40px;
                    }
                    .linkreal {
                        font-size: 1rem;
                        line-height: 1.4rem;
                    }
                }
                .imgscroll:hover {
                    .img {
                        img {
                            margin-top: -170px;
                        }
                    }
                }
            }
            .tiere_double {
                width: 66%;
                overflow: hidden;
                .imagereal {
                    margin-bottom: 45px;
                    display: flex;
                    justify-content: space-between;
                    .cover {
                        position: relative;
                        width: 63%;
                        height: 0;
                        padding-bottom: 27.7%;
                        transition: margin 0.5s ease-out;
                        div {
                            position: absolute;
                            top: 0;
                            left: 0;
                            border-radius: 10px;
                            overflow: hidden;
                            height: 0;
                            padding-bottom: 44%;
                            z-index: 2;
                            width: 100%;
                            img {
                                width: 100%;
                            }
                        }
                        .relief {
                            filter: blur(1rem);
                            -webkit-filter: blur(1rem);
                            -moz-filter: blur(1rem);
                            display: block;
                            position: absolute;
                            left: 50%;
                            bottom: -20px;
                            transform: translateX(-50%);
                            height: 40px;
                            z-index: 1;
                            width: 80%;
                        }
                    }
                    .posts {
                        width: 33%;
                        position: relative;
                        .post1,
                        .post2,
                        .post3 {
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            width: 84%;
                            height: 100%;
                            transition: margin 0.5s ease-out;
                            div {
                                border-radius: 10px;
                                overflow: hidden;
                                z-index: 2;
                                height: 100%;
                                position: relative;
                                img {
                                    width: 100%;
                                }
                            }
                            .relief {
                                filter: blur(1rem);
                                -webkit-filter: blur(1rem);
                                -moz-filter: blur(1rem);
                                display: block;
                                position: absolute;
                                left: 50%;
                                bottom: -20px;
                                transform: translateX(-50%);
                                height: 40px;
                                z-index: 1;
                                width: 80%;
                            }
                        }
                        .post1 {
                            z-index: 4;
                            right: 31px;
                        }
                        .post2 {
                            z-index: 3;
                            right: 15.5px;
                        }
                    }
                    &:hover {
                        .cover {
                            margin-left: -51.7%;
                        }
                        .posts {
                            width: 87%;
                            .post1,
                            .post2,
                            .post3 {
                                width: 32%;
                            }
                            .post1 {
                                margin-right: 62%;
                            }
                            .post2 {
                                z-index: 3;
                                margin-right: 31%;
                            }
                        }
                    }
                }
            }
        }
    }
}
.temoignages {
    background-color: #f5f5f5;
    position: relative;
    overflow: hidden;
    padding: 40px 0;
    &::before {
        content: '';
        position: absolute;
        top: -350px;
        left: -500px;
        width: 1285px;
        height: 998px;
        display: block;
        opacity: 0.25;
        background: url('../images/splash.svg') no-repeat top left;
        background-size: cover;
    }
    .courbe {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background: url('../images/courbe.svg') no-repeat center center;
        background-size: cover;
        width: 100%;
        height: 0;
        padding-bottom: 9%;
    }
    .temoignage_cont {
        position: relative;
        .description {
            display: flex;
            justify-content: center;
        }
        .slick-slider {
            padding-top: 80px;
        }
        .slickcontent {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 30px;
            .slickhead {
                display: flex;
                align-items: center;
                gap: 30px;
                .pic {
                    position: relative;
                    .principal {
                        position: relative;
                        z-index: 2;
                        padding: 10px;
                        img {
                            border: 2px solid white;
                            border-radius: 50%;
                        }
                    }
                    .quote {
                        position: absolute;
                        top: 0;
                        left: -45%;
                        z-index: 3;
                    }
                    .relief {
                        position: absolute;
                        left: 50%;
                        top: 60%;
                        transform: translate(-50%, -50%);
                        z-index: 1;
                        border-radius: 50%;
                        width: 70%;
                        filter: blur(1rem);
                        -webkit-filter: blur(1rem);
                        -moz-filter: blur(1rem);
                    }
                }
                .info {
                    h4 {
                        font-size: 1.8rem;
                        line-height: 2.2rem;
                        font-weight: 700;
                        margin-bottom: 10px;
                    }
                    p {
                        font-weight: 500;
                    }
                }
            }
            .slickcorp {
                p {
                    font-family: 'cormorantgaramond';
                    font-size: 1.4rem;
                    line-height: 1.8rem;
                    width: 65%;
                    margin: 0 auto;
                    text-align: center;
                }
            }
        }
        .slick-prev:before {
            // content: url('../images/arrow_right.svg');
            background-image: url('../images/arrow_right.svg');
            background-size: 27px 52px;
            display: inline-block;
            width: 27px;
            height: 52px;
            content: '';
        }

        .slick-next:before {
            // content: url('../images/arrow_left.svg');
            background-image: url('../images/arrow_left.svg');
            background-size: 27px 52px;
            display: inline-block;
            width: 27px;
            height: 52px;
            content: '';
        }
    }
}

.faq {
    padding: 60px 0;
    .faq_cont {
        .description {
            display: flex;
            justify-content: center;
        }
        .questions {
            display: flex;
            flex-direction: column;
            gap: 30px;
            margin: 30px 0;
            .question {
                background: url('../images/splash.svg') no-repeat center center;
                background-size: 607px 436px;
                background-position: right -250px top -300px;
                border-radius: 40px;
                padding: 20px 30px 20px 60px;
                cursor: pointer;
                transition: background-color 0.2s ease-out;
                .disp {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    h4 {
                        font-size: 1.6rem;
                        line-height: 2rem;
                        font-weight: 700;
                    }
                    span img {
                        height: 60px;
                    }
                }
                .details_cont {
                    transition: opacity 0.5s ease-out;
                    .details {
                        width: 85%;
                        position: relative;
                        padding: 40px 0 50px;
                        margin-top: 20px;
                        &::before {
                            content: '';
                            position: absolute;
                            height: 3px;
                            width: 90px;
                            top: 0;
                            left: 0;
                            display: block;
                            background-color: #bebdd4;
                        }
                        p {
                            font-size: 1.1rem;
                            line-height: 1.5rem;
                            strong {
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
    }
}

.contact {
    padding: 60px 0;
    background-color: #f7f7f7;
    .contact_cont {
        .description {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            p {
                width: 70%;
                text-align: center;
                margin: 0 auto;
            }
        }
        .formulaire {
            width: 80%;
            margin: 40px auto;
            form {
                .formrow {
                    display: flex;
                    justify-content: space-between;
                    position: relative;
                    .inputcont {
                        width: 49%;
                        position: relative;
                        .inputgroup {
                            padding-top: 25px;
                            margin-bottom: 15px;
                            label {
                                position: absolute;
                                left: 22px;
                                top: 42px;
                                font-size: 1rem;
                                line-height: 1.4rem;
                                font-weight: 500;
                                color: $primary;
                                transition: all 0.2s ease-out;
                            }
                            input {
                                border: 2px solid #d3efea;
                                border-radius: 5px;
                                width: 100%;
                                padding: 15px 20px;
                                font-size: 1rem;
                                line-height: 1.4rem;
                                font-family: $font;
                                font-weight: 500;
                                color: $primary;
                            }
                            .error_field {
                                border: 2px solid #ce5050;
                            }
                        }
                        .textareagroup {
                            width: 100%;
                            position: relative;
                            padding-top: 25px;
                            margin-bottom: 10px;
                            label {
                                position: absolute;
                                left: 22px;
                                top: 42px;
                                font-size: 1rem;
                                line-height: 1.4rem;
                                font-weight: 500;
                                color: $primary;
                                transition: all 0.2s ease-out;
                            }
                            textarea {
                                border: 2px solid #d3efea;
                                border-radius: 5px;
                                width: 100%;
                                height: 10rem;
                                padding: 15px 20px;
                                font-size: 1rem;
                                line-height: 1.4rem;
                                font-family: $font;
                                font-weight: 500;
                                color: $primary;
                                resize: none;
                            }
                            .error_field {
                                border: 2px solid #ce5050;
                            }
                        }
                        .focused {
                            label {
                                position: absolute;
                                left: 0;
                                top: 0;
                                font-size: 0.8rem;
                                line-height: 1.2rem;
                                font-weight: 500;
                                color: $secondary;
                            }
                            input {
                                border: 2px solid $secondary;
                            }
                        }
                        .error {
                            position: absolute;
                            top: 85px;
                            left: 0;
                            font-size: 0.7rem;
                            line-height: 0.7rem;
                            color: #ce5050;
                        }
                    }
                    .textcont {
                        width: 100%;
                        position: relative;
                        .error {
                            top: 190px;
                        }
                    }
                }
                .submitrow {
                    display: flex;
                    justify-content: space-between;
                    .mess {
                        font-size: 1rem;
                        margin-top: 40px;
                    }
                    .btn {
                        margin-top: 30px;
                        input {
                            color: white;
                        }
                        &:hover {
                            input {
                                color: $secondary;
                            }
                        }
                    }
                }
            }
        }
    }
}

footer {
    .foot_top {
        background-color: #2d287e;
        .footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 35px 0;
            .logo_container {
                display: flex;
                align-items: center;
                .logo {
                    height: 45px;
                }
                .sep {
                    height: 27px;
                    width: 1px;
                    background-color: #dddddd;
                    margin: 0 20px;
                }
                .veeva {
                    height: 45px;
                }
            }
            .rs {
                display: flex;
                align-items: center;
                gap: 10px;
                p {
                    color: white;
                    font-weight: 500;
                }
            }
        }
    }
    .foot_bottom {
        background-color: white;
        .footer {
            padding: 15px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .signature {
                font-weight: 500;
            }
            .by {
                display: flex;
                align-items: center;
                gap: 15px;
            }
        }
    }
}
