$smallscreen1: 1220px;
$smallscreen2: 750px;
$smallerscreen1: 749px;
$smallerscreen2: 450px;
$phonescreen1: 449px;
$phonescreen2: 320px;

@media screen and (max-width: $smallscreen1) and (min-width: $smallscreen2) {
}

// @media screen and (max-width: $smallerscreen1) and (min-width: $smallerscreen2) {
// }

@media screen and (max-width: $smallerscreen1) and (min-width: $phonescreen2) {
    body {
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
    }

    .btn {
        button,
        input {
            padding: 10px 15px;
            font-size: 14px;
        }
    }
    .marge button {
        padding: 10px 15px;
    }

    .page_cont {
        width: 90%;
        max-width: 90%;
    }
    .title {
        font-size: 2.2rem;
        line-height: 2.6rem;
    }
    .subtitle {
        font-size: 1.1rem;
        line-height: 1.5rem;
    }

    header {
        .header {
            padding: 20px 0;
            .logo_container {
                margin-right: 15%;
                .logo {
                    height: 30px;
                }
                .sep {
                    height: 20px;
                    margin: 0 15px;
                }
                .veeva {
                    height: 30px;
                }
            }
            .headlink {
                strong {
                    display: none;
                }
                img {
                    width: 20px;
                }
            }
            .btn {
                display: none;
            }
        }
    }
    .slider_cont {
        padding-top: 70px;
        .slider {
            .slide {
                flex-direction: column-reverse;
                height: auto;
                justify-content: space-around;
                .content {
                    width: 100%;
                    p {
                        width: 100%;
                        margin-bottom: 40px;
                        font-size: 1.2rem;
                        line-height: 1.6rem;
                    }
                    .cta {
                        gap: 30px;
                    }
                }
                .kv {
                    width: 60%;
                    margin: 20px auto;
                }
                .link {
                    position: relative;
                }
            }
            .reference {
                flex-wrap: wrap;
                width: 100%;
                justify-content: flex-start;
                margin: 30px auto;
                div {
                    width: 33.33%;
                    text-align: center;
                    img {
                        width: 70%;
                        margin-bottom: 20px;
                    }
                }
            }
        }
        .scroll {
            display: none;
        }
    }
    .services {
        .services_cont {
            .description {
                flex-direction: column-reverse;
                padding: 60px 0;
                .content {
                    width: 100%;
                    margin-top: 40px;
                    h2 {
                        margin-bottom: 20px;
                        width: 100%;
                    }
                    p {
                        margin-bottom: 0px;
                    }
                }
                .arrow {
                    display: none;
                }
                .image {
                    width: 90%;
                }
            }
            .bull1,
            .bull2,
            .bull3 {
                width: 400px;
                height: 400px;
                opacity: 0.45;
                z-index: -3;
            }
        }
        .services_icons {
            margin-top: 20px;
            .services_icons_cont {
                .flex {
                    flex-direction: column;
                    gap: 20px;
                    margin-bottom: 20px;
                    div {
                        gap: 20px;
                        width: 80%;
                        margin: 0 auto;
                        img {
                            height: 100px;
                            width: 100px;
                        }
                    }
                }
            }
        }
    }

    .maitrise {
        .maitrise_cont {
            .description {
                width: 100%;
                img {
                    height: 120px;
                    margin-bottom: 40px;
                }
                h2 {
                    width: 100%;
                }
            }
            .details {
                flex-direction: column;
                align-items: center;
                gap: 20px;
                padding: 20px 0;
                div {
                    width: 90%;
                    text-align: center;
                    .icon {
                        height: 120px;
                        margin-bottom: 40px;
                    }
                    h3 {
                        text-align: left;
                        img {
                            height: 35px;
                        }
                        span {
                            font-size: 1.4rem;
                            line-height: 1.8rem;
                        }
                    }
                    p {
                        text-align: left;
                        margin-bottom: 20px;
                    }
                    ul {
                        li {
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
    .references {
        .references_cont {
            padding: 60px 0;
            .description {
                width: 100%;
                h2 {
                    width: 100%;
                }
            }
            .refs {
                width: 100%;
                .block {
                    margin: 30px 0;
                    .logos {
                        gap: 5%;
                        flex-wrap: wrap;
                        width: 100%;
                        div {
                            width: 45%;
                            padding: 30px 30px;
                        }
                    }
                }
                .flex {
                    flex-direction: column;
                    align-items: center;
                    .double {
                        width: 100%;
                    }
                    .single {
                        width: 100%;
                    }
                }
            }
        }
    }

    .realisations {
        .realisations_cont {
            padding: 20px 0;
            .description {
                width: 100%;
                h2 {
                    width: 100%;
                }
            }
            .real {
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: space-between;
                margin: 30px 0;
                .realisation {
                    padding: 35px 35px 30px;
                    .imagereal {
                        margin-bottom: 30px;
                        .logocont {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            opacity: 0;
                            transition: opacity 0.5s ease-out;
                            img {
                                width: 40%;
                            }
                        }
                        .linkreal {
                            font-size: 1.2rem;
                            line-height: 1.6rem;
                            text-decoration: underline;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            width: 100%;
                            text-align: center;
                            transition: margin 0.5s ease-out;
                        }
                    }
                    .imgscroll:hover {
                        .img {
                            img {
                                margin-top: -75%;
                            }
                        }
                    }
                    .imglogo {
                        &:hover {
                            margin-top: -20%;
                        }
                    }
                    h3 {
                        font-size: 2rem;
                        line-height: 2.4rem;
                    }
                    p {
                        font-size: 1.2rem;
                        line-height: 1.6rem;
                    }
                }
                .demi {
                    width: 100%;
                    margin-bottom: 20px;
                }
                .tiere,
                .tiere_double {
                    width: 100%;
                    padding: 35px 35px 30px;
                    margin-bottom: 20px;
                    h3 {
                        margin-bottom: 10px;
                    }
                    .imagereal {
                        margin-bottom: 30px;
                        .img {
                            border-radius: 20px;
                        }
                        .relief {
                            bottom: -25px;
                            height: 45px;
                        }
                        .linkreal {
                            font-size: 1rem;
                            line-height: 1.4rem;
                        }
                    }
                    .imgscroll:hover {
                        .img {
                            img {
                                margin-top: -170px;
                            }
                        }
                    }
                }
                .tiere_double {
                    width: 100%;
                    .imagereal {
                        flex-direction: column;
                        gap: 15px;
                        margin-bottom: 30px;
                        .cover {
                            width: 100%;
                            height: 0;
                            padding-bottom: 43.7%;
                        }
                        .posts {
                            width: 100%;
                            position: relative;
                            display: flex;
                            justify-content: space-between;
                            .post1,
                            .post2,
                            .post3 {
                                position: relative;
                                width: 30%;
                                height: auto;
                                div {
                                    img {
                                        width: 100%;
                                    }
                                }
                                .relief {
                                    bottom: -15px;
                                    height: 30px;
                                }
                            }
                            .post1 {
                                z-index: 4;
                                right: auto;
                            }
                            .post2 {
                                z-index: 3;
                                right: auto;
                            }
                        }
                        &:hover {
                            .cover {
                                margin-left: 0;
                            }
                            .posts {
                                width: auto;
                                .post1,
                                .post2,
                                .post3 {
                                    width: auto;
                                }
                                .post1 {
                                    margin-right: 0;
                                }
                                .post2 {
                                    z-index: 3;
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .temoignages {
        padding: 40px 0;
        .courbe {
            width: 200%;
            padding-bottom: 27%;
        }
        .temoignage_cont {
            .description {
                display: flex;
            }
            .slick-slider {
                padding-top: 60px;
            }
            .slickcontent {
                gap: 20px;
                .slickhead {
                    flex-direction: column;
                    gap: 10px;
                    text-align: center;
                    .pic {
                        .principal {
                            img {
                                height: 100px;
                            }
                        }
                        .quote {
                            left: -20%;
                            height: 50px;
                        }
                    }
                    .info {
                        h4 {
                            font-size: 1.6rem;
                            line-height: 2rem;
                            margin-bottom: 5px;
                        }
                    }
                }
                .slickcorp {
                    p {
                        font-size: 1.3rem;
                        line-height: 1.7rem;
                        width: 90%;
                    }
                }
            }
            .slick-prev:before {
                background-size: 10px 30px;
                width: 10px;
                height: 30px;
                margin-left: 10px;
            }

            .slick-next:before {
                background-size: 10px 30px;
                width: 10px;
                height: 30px;
                margin-right: 10px;
            }
            .slicklogo {
                width: 140px;
            }
        }
    }

    .faq {
        padding: 50px 0;
        .faq_cont {
            .description {
                text-align: center;
            }
            .questions {
                gap: 20px;
                margin: 20px 0;
                .question {
                    border-radius: 30px;
                    padding: 20px 20px 20px 30px;
                    .disp {
                        h4 {
                            font-size: 1.1rem;
                            line-height: 1.5rem;
                            width: 85%;
                        }
                        span img {
                            height: 30px;
                            width: 30px;
                        }
                    }
                    .details_cont {
                        .details {
                            width: 100%;
                            padding: 25px 0 20px;
                            margin-top: 20px;
                            &::before {
                                height: 2px;
                                width: 80px;
                            }
                            p {
                                font-size: 1rem;
                                line-height: 1.4rem;
                            }
                        }
                    }
                }
            }
        }
    }

    .contact {
        padding: 50px 0;
        .contact_cont {
            .description {
                p {
                    width: 100%;
                }
            }
            .formulaire {
                width: 90%;
                margin: 40px auto;
                form {
                    .formrow {
                        flex-direction: column;
                        .inputcont {
                            width: 100%;
                            .inputgroup {
                                padding-top: 25px;
                                margin-bottom: 15px;
                                label {
                                    left: 17px;
                                    top: 37px;
                                }
                                input {
                                    padding: 10px 15px;
                                }
                            }
                            .textareagroup {
                                width: 100%;
                                position: relative;
                                padding-top: 25px;
                                margin-bottom: 10px;
                                label {
                                    left: 17px;
                                    top: 37px;
                                }
                                textarea {
                                    height: 12rem;
                                    padding: 10px 15px;
                                }
                            }
                            .focused {
                                label {
                                    position: absolute;
                                    left: 0;
                                    top: 3px;
                                    font-size: 0.8rem;
                                    line-height: 1.2rem;
                                    font-weight: 500;
                                }
                            }
                            .error {
                                top: 77px;
                            }
                        }
                    }
                    .submitrow {
                        .mess {
                            margin-top: 20px;
                        }
                        .btn {
                            margin-top: 20px;
                            input {
                                color: white;
                            }
                        }
                    }
                }
            }
        }
    }

    footer {
        .foot_top {
            .footer {
                padding: 20px 0;
                .logo_container {
                    .logo {
                        height: 30px;
                    }
                    .sep {
                        height: 20px;
                        margin: 0 15px;
                    }
                    .veeva {
                        height: 30px;
                    }
                }
                .rs {
                    gap: 10px;
                    p {
                        display: none;
                    }
                    a img {
                        height: 20px;
                    }
                }
            }
        }
        .foot_bottom {
            .footer {
                flex-direction: column;
                gap: 10px;
                .signature {
                    font-size: 0.8rem;
                }
                .by {
                    font-size: 0.8rem;
                }
            }
        }
    }
}
